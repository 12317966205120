import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import Loading from "../../general/status/Loading";

const Efa = () => {
  const { productId } = useParams();
  const { auth } = useAuth();
  const [err, setErr] = useState("");
  const [efa, setEfa] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  useEffect(() => {
    getEfa();
  }, [productId]);

  const getEfa = async () => {
    setIsLoading(true);
    const url = `/products/${productId}/v2/efa`;
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setEfa(data);
      setErr("");
    } catch (err) {
      console.log(err);
      if (err?.response.status === 418) {
        setErr(
          `⚠️ Missing ${err.response.data.name} in the ${err.response.data.location}, which is required for the result`,
        );
      } else {
        setErr(err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const V2Score = ({ score }) => {
    return (
      <div
        className="card mb-3 mb-lg-5"
        style={{ maxWidth: "1500px", margin: "auto" }}
      >
        <div className="card-header">
          <div className="row justify-content-between align-items-center flex-grow-1">
            <div className="col-md">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="card-header-title">PEF</h3>
              </div>
            </div>
          </div>
        </div>
        <table className="table table-text-center">
          <thead className="thead-light">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Abbreviation</th>
              <th scope="col">Criterion (english)</th>
              <th scope="col">Criterion (german)</th>
              <th scope="col">Score</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(score).map((criterion, index) => {
              return (
                <tr key={criterion.index}>
                  <td scope="row">{index + 1}</td>
                  <td scope="row">{criterion.abbrev}</td>
                  <td scope="row">{criterion.criteria_english}</td>
                  <td scope="row">{criterion.criteria_german}</td>
                  <td scope="row">
                    {(Math.round(criterion.bts * 100) / 100).toFixed(2)}
                  </td>
                </tr>
              );
            })}
            <tr key={1313311331}>
              <td scope="row">{Object.values(score).length + 1}</td>
              <td scope="row">Total</td>
              <td scope="row">Total</td>
              <td scope="row">Gesamt</td>
              <td scope="row">
                {(
                  Math.round(
                    Object.values(score)
                      .map((c) => c.bts)
                      .reduce((partialSum, a) => partialSum + a, 0) * 100,
                  ) / 100
                ).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const EfaCategoryTable = ({ components, category }) => {
    return (
      <div className="card mb-3 mb-lg-5" style={{ margin: "auto" }}>
        <div className="card-header">
          <div className="row justify-content-between align-items-center flex-grow-1">
            <div className="col-md">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="card-header-title">{category.name}</h3>
              </div>
            </div>
          </div>
        </div>
        <table className="table table-text-center table-thead-bordered">
          <thead className="thead-light">
            <tr>
              <th colspan="6"></th>
              <th colspan="3">Material</th>
              <th colspan="2">Production</th>
              <th colspan="3">Transport</th>
              <th colspan="3">Incineration</th>

              <th colspan="2">Sum</th>
            </tr>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Function</th>
              <th scope="col">Material</th>
              <th scope="col">Recyclat in %</th>
              <th scope="col">Weight in g</th>
              <th scope="col">Share in %</th>

              <th scope="col">{category.unit} / kg</th>
              <th scope="col">{category.unit} / Component</th>
              <th scope="col">Share %</th>

              <th scope="col">Add-on factor</th>
              <th scope="col">{category.unit} / Component</th>

              <th scope="col">Recyclat in %</th>
              <th scope="col">{category.unit} / Component</th>
              <th scope="col">Share %</th>

              <th scope="col">{category.unit} / kg</th>
              <th scope="col">{category.unit} / Component </th>
              <th scope="col">Share %</th>

              <th scope="col">{category.unit} / Component</th>
              <th scope="col">Share %</th>
            </tr>
          </thead>
          <tbody>
            {components.map((component, index) => {
              return (
                <tr key={component.index}>
                  <td scope="row">{index + 1}</td>
                  <td scope="row">{component.function}</td>
                  <td scope="row">{component.material}</td>
                  <td scope="row">{component.recyclate}</td>
                  <td scope="row">{component.weight.toFixed(2)}</td>
                  <td scope="row">{component.share.toFixed(2)}%</td>

                  <td scope="row">
                    {category.components[index].material.eco_invent_src.toFixed(
                      2,
                    )}
                  </td>
                  <td scope="row">
                    {category.components[index].material.weighted.toFixed(2)}
                  </td>
                  <td scope="row">
                    {category.components[index].material.share.toFixed(2)}
                  </td>
                  <td scope="row">10%</td>
                  <td scope="row">
                    {category.components[index].production.weighted.toFixed(2)}
                  </td>
                  <td scope="row">
                    {category.components[
                      index
                    ].transport.eco_invent_src.toFixed(2)}
                  </td>
                  <td scope="row">
                    {category.components[index].transport.weighted.toFixed(2)}
                  </td>
                  <td scope="row">
                    {category.components[index].transport?.share?.toFixed(2) ??
                      "0.00"}
                  </td>
                  <td scope="row">
                    {category.components[
                      index
                    ].incineration.eco_invent_src.toFixed(2)}
                  </td>
                  <td scope="row">
                    {category.components[index].incineration.weighted.toFixed(
                      2,
                    )}
                  </td>
                  <td scope="row">
                    {category.components[index].incineration.share.toFixed(2)}
                  </td>
                  <td scope="row">
                    {category.components[index].sum.result.toFixed(2)}
                  </td>
                  <td scope="row">
                    {category.components[index].sum.share.toFixed(2)}
                  </td>
                </tr>
              );
            })}
            <tr key={category.components.length}>
              <td scope="row">{category.components.length}</td>
              <td scope="row">Sum</td>
              <td scope="row"></td>
              <td scope="row"></td>
              <td scope="row"></td>
              <td scope="row">100%</td>

              <td scope="row"></td>
              <td scope="row">
                {category.components
                  .map((c) => c.material.weighted)
                  .reduce((a, c) => a + c, 0)
                  .toFixed(2)}
              </td>
              <td scope="row">100%</td>
              <td scope="row"></td>
              <td scope="row">
                {category.components
                  .map((c) => c.production.weighted)
                  .reduce((a, c) => a + c, 0)
                  .toFixed(2)}
              </td>
              <td scope="row"></td>
              <td scope="row">
                <td scope="row">
                  {category.components
                    .map((c) => c.transport.weighted)
                    .reduce((a, c) => a + c, 0)
                    .toFixed(2)}
                </td>
              </td>
              <td scope="row">100%</td>
              <td scope="row"></td>
              <td scope="row">
                <td scope="row">
                  {category.components
                    .map((c) => c.incineration.weighted)
                    .reduce((a, c) => a + c, 0)
                    .toFixed(2)}
                </td>
              </td>
              <td scope="row">100%</td>
              <td scope="row">
                {category.components
                  .map((c) => c.sum.result)
                  .reduce((a, c) => a + c, 0)
                  .toFixed(2)}
              </td>
              <td scope="row">100%</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  return (
    <div style={{ width: "100%" }}>
      {isLoading ? (
        <Loading />
      ) : err ? (
        <div
          className={err ? "alert alert-soft-danger" : "offscreen"}
          role="alert"
        >
          {err}
        </div>
      ) : (
        <>
          {efa.categories && efa.components && (
            <>
              {efa.categories.map((category) => (
                <EfaCategoryTable
                  category={category}
                  components={efa.components}
                />
              ))}
            </>
          )}
          {efa.pef && <V2Score score={efa.pef} />}
        </>
      )}
    </div>
  );
};

export default Efa;
