import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import LoadingAndErrIndicator from "../general/status/LoadingAndErrorIndicator";
import React from "react";
import Warning from "../general/status/Warning";

const QuickChecks = () => {
  const { jobId } = useParams();
  const { auth } = useAuth();
  const [err, setErr] = useState("");
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getResult();
  }, [jobId]);

  const getResult = async () => {
    setIsLoading(true);
    const url = `/jobs/${jobId}/check`;
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setResults(data); // Assuming the API returns an array of objects
      setErr("");
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="card mb-3 mb-lg-5"
      style={{ maxWidth: "1500px", margin: "auto" }}
    >
      <div className="card-header">
        <div className="row justify-content-between align-items-center flex-grow-1">
          <div className="col-md">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="card-header-title">QUICKcheck</h3>
            </div>
          </div>
        </div>
      </div>
      {results?.length === 0 && !isLoading && err === "" && (
        <Warning
          warning={
            "There are no results, likely due to either missing products for this job or incomplete questionnaire submissions."
          }
        />
      )}
      {err || isLoading ? (
        <div className="card-body">
          <LoadingAndErrIndicator
            err={err}
            isLoading={isLoading}
            loadingMessage={"Fetching QUICKchecks..."}
          />
        </div>
      ) : (
        results.length > 0 && (
          <table className="table table-text-center">
            <thead className="thead-light">
              <tr>
                <th scope="col">#</th>
                {/* Display the keys from the first object as column headers */}
                {Object.keys(results[0]).map((key, index) => (
                  <th scope="col" key={index}>
                    {key}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {/* Loop through each object in the results array */}
              {results.map((result, rowIndex) => (
                <tr key={rowIndex}>
                  <td scope="row">{rowIndex + 1}</td>
                  {/* Loop through the keys of each result object */}
                  {Object.keys(result).map((key, colIndex) => (
                    <td key={colIndex}>
                      {/* Access the value property inside each key */}
                      {typeof result[key].value === "string"
                        ? result[key].value
                            .split("\n")
                            .map((line, lineIndex) => (
                              <React.Fragment key={lineIndex}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))
                        : result[key].value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )
      )}
    </div>
  );
};

export default QuickChecks;
